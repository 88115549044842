



















































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { MetaInfo } from 'vue-meta';
import LoadingIndicator from '../../components/LoadingIndicator.vue';
import PropertyEditor from '../../components/collections/PropertyEditor.vue';

export default Vue.extend({
  components: {
    LoadingIndicator,
    PropertyEditor,
  },
  computed: {
    ...mapGetters('collections', [
      'collections',
      'currentCollection',
    ]),
    ...mapFields('collections', [
      'showPropertyEditor',
    ]),
  },
  async created() {
    this.loadCollections();
  },
  methods: {
    ...mapActions('collections', [
      'loadCollections',
      'addCollection',
    ]),
  },
  metaInfo(): MetaInfo {
    return {
      title: 'Collections',
    }
  },
})
