






































import Vue from 'vue';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default Vue.extend({
  computed: {
    ...mapFields('collections', [
      'currentCollection.name',
      'currentCollection.description',
      'currentCollection.hidden'
    ])
  },
  methods: {
    ...mapActions('collections', [
      'saveCollection',
    ]),
  },
});
